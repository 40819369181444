.chatbox {
    /* position: fixed; */
    bottom: 20px;
    right: 20px;
    width: 320px;
    /* height: 500px; */
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }
  
  .chatbox-header {
    background: #ff3300;
    color: white;
    padding: 8px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .chatbox-header h3 {
    color: rgb(5, 3, 3);
    font-size: 16px;
    font-weight: bolder;
    margin: 0;
  }
  
  .chatbox-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 350px !important;
    max-height: 350px !important;
  }
  .chatbox-body::-webkit-scrollbar{
   display: none !important;
  }
  
  .chat-message {
    max-width: 80%;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    word-wrap: break-word;
  }
  
  .self {
    align-self: flex-end;
    background: #007bff;
    color: white;
    text-align: right;
  }
  
  .other {
    align-self: flex-start;
    background: #f1f1f1;
    color: black;
    text-align: left;
  }
  
  .chatbox-footer {
    display: flex;
    padding: 4px 5px;
    border-top: 1px solid #ddd;
    background: #ff3300;
  }
  
  .chatbox-footer input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    outline: none;
  }
  
  .chatbox-footer button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px;
    margin-left: 4px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    line-height: 10px;
  }
  .css-1wnsr1i{
    padding: 0 !important;
    background-color: transparent !important;
    width: auto !important;
    border: 2px solid #ff3300 !important;
    right: 0 !important;
    left: auto !important;
    display: flex;
  }

  @media screen and (max-width: 1024px) { 
    .css-1wnsr1i {
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 768px) { 
    .css-1wnsr1i {
        transform: translate(-55%, -55%);
    }
}

  .close-button {
    margin-left: auto;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    color: black;
  }
  
  .close-button:hover {
    color: darkred;
  }