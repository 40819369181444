/* DeleteAccountPolicy.css */

/* Base styles */
.deleteAccount {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2em;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    h1 {
      font-size: 1.5em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  
    h3 {
      font-size: 1em;
    }
  
    p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    div {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.2em;
    }
  
    h2 {
      font-size: 1em;
    }
  
    h3 {
      font-size: 0.9em;
    }
  
    p {
      font-size: 0.8em;
    }
  }
  