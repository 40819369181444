.mw-100 {
  max-width: 100%;
}
.w-100 {
  width: 100% !important;
}
.text-light {
  color: #fff !important;
}
.detail .card {
  border-radius: 30px;
  border: 0;
}
.text-center {
  text-align: center;
}
.detail {
  background-image: url(./bg.png);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding: 10px;
}
.p-0 {
  padding: 0 !important;
}
.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.between p {
  margin: 0;
}
.detail .card .between img {
  max-width: 200px;
  margin: 10px;
}
.my-3 {
  margin: 20px 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: #fff;
}
th {
  background-color: red;
}

.stepper-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: red;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid red;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.step-name {
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .center {
    height: 100%;
    padding: 50px 10px;
  }
  .w-85 , .w-95 {
    width: 100% !important;
  }

}
.w-85 {
  width: 85% !important;
}
.w-95 {
  width: 95% !important;
}
.mx-2{
  margin: 0 10px;
}
