/* TermsAndConditions.css */
.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1em;
    line-height: 1.6;
  }
  
  ul {
    margin-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 600px) {
    .terms-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.2em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  }
  