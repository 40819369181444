
.container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Typography */
  h1, h2, h3, h4 {
    /* font-family: Arial, sans-serif; */
    color: #333;
  }
  
  p {
    /* font-family: Arial, sans-serif; */
    color: #666;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  /* Link styles */
  a {
    color: #007BFF;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 1.3rem;
    }
  
    p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    h2 {
      font-size: 1.2rem;
    }
  
    h3 {
      font-size: 1rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  }
  
  